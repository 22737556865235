import React, { useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import {
	load as loadIntercom,
	boot as bootIntercom,
	update as updateIntercom,
} from "./intercom";
import { useAuth } from "./context/auth";
import { useWindowSize } from "@withjuly/frontend-common";

export const IntercomProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { user } = useAuth();
	const router = useRouter();
	const windowSize = useWindowSize();

	useEffect(() => {
		if (typeof window !== "undefined" && user && user.intercomEmailHash) {
			loadIntercom();
			bootIntercom(user.email, user.intercomEmailHash);

			updateIntercom({
				hide_default_launcher: (windowSize.width ?? 766) <= 765,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		const handleRouteChange = () => {
			if (typeof window !== "undefined") {
				updateIntercom({
					hide_default_launcher: (windowSize.width ?? 766) <= 765,
				});
			}
		};

		router.events.on("routeChangeStart", handleRouteChange);

		// If the component is unmounted, unsubscribe
		// from the event with the `off` method:
		return () => {
			router.events.off("routeChangeStart", handleRouteChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.events]);

	return <>{children}</>;
};
