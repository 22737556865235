import { AgencyBilling, AgencyBillingStatusSchema } from "@withjuly/fabric";
import React, { ReactNode, useContext, useEffect } from "react";
import { trpc } from "~/components/Utility/trpc";
import { useAgencyStore } from "./agency";

export const AgencyBillingContext = React.createContext<
	AgencyBilling | undefined
>(undefined);

const useProvideAgencyBilling = () => {
	const { data: billing } = trpc.agency.billing.get.useQuery();

	const $setIsPayingCustomer = useAgencyStore(
		(store) => store.setIsPayingCustomer,
	);

	useEffect(() => {
		if (billing && billing.status !== AgencyBillingStatusSchema.enum.active) {
			$setIsPayingCustomer(false);
		} else {
			$setIsPayingCustomer(true);
		}
	}, [$setIsPayingCustomer, billing]);

	return billing;
};

export const AgencyBillingProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const value = useProvideAgencyBilling();

	return (
		<AgencyBillingContext.Provider value={value}>
			{children}
		</AgencyBillingContext.Provider>
	);
};

export const useAgencyBilling = () => useContext(AgencyBillingContext);
