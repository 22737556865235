import { CreatorProfile, nonNull } from "@withjuly/fabric";
import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { trpc } from "~/components/Utility/trpc";
import { CREATOR_PROFILE_UUID_KEY } from "../api/client";
import { useAuth } from "./auth";
import debounce from "lodash.debounce";

type DebouncedFunc = ReturnType<typeof debounce>;

interface CreatorContextData {
	availableCreatorProfiles: CreatorProfile[];
	creatorProfile?: CreatorProfile;
	setCreatorProfileUuid(creatorProfileUuid?: string): void;
	isImpersonating: boolean;
	setQueuedOperations: (
		update: (
			current: Record<string, DebouncedFunc>,
		) => Record<string, DebouncedFunc>,
	) => void;
}

export const CreatorContext = React.createContext<CreatorContextData>({
	availableCreatorProfiles: [],
	setCreatorProfileUuid() {
		// Do nothing
	},
	isImpersonating: false,
	setQueuedOperations() {
		// Do nothing
	},
});

const useProvideCreator = () => {
	const [creatorProfile, setCreatorProfile] = useState<CreatorProfile>();
	const queuedOperations = useRef<Record<string, DebouncedFunc>>({});
	const [creatorUuid, setCreatorUuid] = useState<string | undefined>(undefined);

	const { user } = useAuth();
	const utils = trpc.useContext();
	const availableCreatorProfiles = [user?.creatorProfile].filter(nonNull);

	trpc.agency.getCreatorDetails.useQuery(creatorUuid ?? "", {
		enabled:
			creatorUuid !== undefined &&
			creatorUuid !== "" &&
			(!user || !user.creatorProfile),
		onSuccess: (data) => {
			localStorage.setItem(CREATOR_PROFILE_UUID_KEY, data?.uuid ?? "");
			setCreatorProfile(data);
		},
	});

	const wrappedSetCreatorProfileUuid = useCallback(
		async (creatorProfileUuid?: string) => {
			// Run all queued operations (for example, debouncing mediakit edits)
			// before we switch to the new creator
			//
			// Note that flush will only run if there's a pending operation on the
			// debounced function. If the function has already ran flushing will be a
			// noop.
			if (creatorProfileUuid && creatorProfile?.uuid !== creatorProfileUuid) {
				await Promise.all(
					Object.values(queuedOperations.current).map((q) => q.flush()),
				);
				queuedOperations.current = {};
			}

			if (creatorProfileUuid) {
				const nextCreatorProfile = availableCreatorProfiles.find(
					(cp) => cp.uuid === creatorProfileUuid,
				);
				if (nextCreatorProfile) {
					localStorage.setItem(
						CREATOR_PROFILE_UUID_KEY,
						nextCreatorProfile?.uuid ?? "",
					);
					setCreatorProfile(nextCreatorProfile);
				} else {
					// If not found in available profiles we fetch the creator profile
					setCreatorUuid(creatorProfileUuid);
				}
			} else {
				// When clearing creator profile, we want to default to the user's
				localStorage.setItem(
					CREATOR_PROFILE_UUID_KEY,
					user?.creatorProfile?.uuid ?? "",
				);
				setCreatorProfile(user?.creatorProfile);
			}
			utils.mediaKit.invalidate();
			utils.dealFlow.invalidate();
			utils.match.invalidate();
			utils.payments.invalidate();
			utils.wishlist.invalidate();
			utils.template.invalidate();
			utils.profile.invalidate();
		},
		// Adding availableCreatorProfiles breaks it
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[user?.creatorProfile, utils, creatorProfile?.uuid],
	);

	useEffect(() => {
		if (typeof window !== "undefined") {
			if (user && user.creatorProfile) {
				wrappedSetCreatorProfileUuid(user.creatorProfile.uuid);
			} else {
				const uuid =
					localStorage.getItem(CREATOR_PROFILE_UUID_KEY) ?? undefined;
				wrappedSetCreatorProfileUuid(uuid);
			}
		}
	}, [user, wrappedSetCreatorProfileUuid]);

	const isImpersonating = user?.creatorProfile?.uuid !== creatorProfile?.uuid;

	return {
		availableCreatorProfiles,
		creatorProfile,
		setCreatorProfileUuid: wrappedSetCreatorProfileUuid,
		isImpersonating,
		setQueuedOperations: (
			update: (
				current: Record<string, DebouncedFunc>,
			) => Record<string, DebouncedFunc>,
		) => {
			queuedOperations.current = update(queuedOperations.current);
		},
	};
};

export const CreatorProvider: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	const value = useProvideCreator();
	return (
		<CreatorContext.Provider value={value}>{children}</CreatorContext.Provider>
	);
};

export const useCreator = () => useContext(CreatorContext);

export interface RequireCreatorProps {
	children: React.ReactNode;
}
