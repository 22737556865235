import { createStore } from "zustand/vanilla";

interface AgencyState {
	isPayingCustomer: boolean;
}

interface AgencyActions {
	setIsPayingCustomer: (isPaying: boolean) => void;
}

export type AgencyStore = AgencyState & AgencyActions;

const defaultInitState: AgencyState = {
	isPayingCustomer: true,
};

export const createAgencyStore = (
	initState: AgencyState = defaultInitState,
) => {
	return createStore<AgencyStore>()((set) => ({
		...initState,
		setIsPayingCustomer: (isPaying) => {
			set((state) => ({ ...state, isPayingCustomer: isPaying }));
		},
	}));
};
