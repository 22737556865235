import React, { ReactNode, createContext, useContext, useRef } from "react";
import { RouterOutput, trpc } from "~/components/Utility/trpc";
import { AgencyBillingProvider } from "./agency-billing";
import {
	type AgencyStore,
	createAgencyStore,
} from "~/utils/store/agency-store";
import { useStore } from "zustand";

export type AgencyProfile = RouterOutput["agency"]["get"];

interface AgencyContextData {
	agencyProfile?: AgencyProfile;
	isLoading: boolean;
}

export const AgencyContext = React.createContext<AgencyContextData>({
	isLoading: false,
});

const useProvideAgency = () => {
	const { data: agencyProfile, isLoading } = trpc.agency.get.useQuery(
		undefined,
		{
			retry: false,
			trpc: {
				context: {
					skipBatch: true,
				},
			},
		},
	);

	return {
		agencyProfile,
		isLoading,
	};
};

export const AgencyProvider: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	const value = useProvideAgency();
	return (
		<AgencyStoreProvider>
			<AgencyContext.Provider value={value}>
				<AgencyBillingProvider>{children}</AgencyBillingProvider>
			</AgencyContext.Provider>
		</AgencyStoreProvider>
	);
};

export const useAgency = () => useContext(AgencyContext);

export interface RequireAgencyProps {
	children: React.ReactNode;
}

export type AgencyStoreApi = ReturnType<typeof createAgencyStore>;

export const AgencyStoreContext = createContext<AgencyStoreApi | undefined>(
	undefined,
);

export interface AgencyStoreProviderProps {
	children: ReactNode;
}

export const AgencyStoreProvider: React.FC<AgencyStoreProviderProps> = ({
	children,
}) => {
	const storeRef = useRef<AgencyStoreApi>();
	if (!storeRef.current) {
		storeRef.current = createAgencyStore();
	}

	return (
		<AgencyStoreContext.Provider value={storeRef.current}>
			{children}
		</AgencyStoreContext.Provider>
	);
};

export const useAgencyStore = <T,>(selector: (store: AgencyStore) => T): T => {
	const agencyStoreContext = useContext(AgencyStoreContext);

	if (!agencyStoreContext) {
		throw new Error(`useAgencyStore must be used within AgencyStoreProvider`);
	}

	return useStore(agencyStoreContext, selector);
};
